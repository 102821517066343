import { Box, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import BalanceIcon from "@mui/icons-material/AccountBalance";
import AccountsBalance from "./AccountsBalance";
import React, { useEffect } from "react";
import { ToolTitle } from "./Index";
import { useConfiguredAxios } from "../../../Utils/AxiosInstance";
import { CcT } from "../../../Utils/Types";

export default function Finances() {
  const axiosInstance = useConfiguredAxios();
  const [currentType, setCurrentType] = React.useState<CcT | null>(null);
  const [types, setTypes] = React.useState<CcT[]>([]);

  useEffect(() => {
    const fetch = async () => {
      const res = await axiosInstance.get('/api/creditcards/headless/availabletypes');
      const types = res.data as CcT[];

      setTypes(types);
      const type = types.find((cc: CcT) => cc.headless_using) as CcT;
      setCurrentType(type);
    };
    fetch().then();
  }, []);

  const handleChange = async (event: SelectChangeEvent<string>) => {
    await axiosInstance.put(`/api/creditcards/headless/creditcardtype/${event.target.value}`)
      .then(() => {
        const type = types.find((cc: CcT) => cc.id === parseInt(event.target.value));
        setCurrentType(type || null);
      });
  };

  return (
    <Box sx={{width: '100%', display: 'flex', flexDirection: 'column', gap: 3}}>
      <Box sx={{width: '100%', display: 'flex', alignItems: 'center'}}>
        {ToolTitle({
          title: 'Headless CC',
          icon: <CreditCardIcon sx={{fontSize: '35px'}}/>
        })}
        <Select
          value={currentType?.id?.toString() || ''}
          onChange={handleChange}
          displayEmpty
          size={'small'}
        >
          {types.map((type) => (
            <MenuItem key={type.id} value={type.id.toString()}>
              {type.name}
            </MenuItem>
          ))}
        </Select>
      </Box>

      <Box sx={{width: '100%', display: 'flex', alignItems: 'center'}}>
        {ToolTitle({
          title: 'Accounts Balance',
          icon: <BalanceIcon sx={{fontSize: '35px'}}/>
        })}
        <AccountsBalance/>
      </Box>
    </Box>
  );
}
