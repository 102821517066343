export interface AccountT {
  id: number;
  cc_id: number;
  cc_info: CreditCardT;
  email: string;
  name: string;
  phone: string;
  password: string;
  address: string;
  zip: string;
  city: string;
  state: string;
  country: string;
  comment: string;
  platform: string;
  manager_synced: boolean;
  manager_status: string;
  tm_created: boolean;
  tm_card_added: boolean;
  is_locked: boolean;
  in_use: boolean;
  in_use_by: string;
  last_in_use: string;
  lists: Array<ListT>;
  proxy_id: number;
  proxy: ProxyT;
  status_id: number;
  status: StatusT;
  updated_at: string;
  created_at: string;
}

export interface FilterT {
  field: string;
  operator: Operator;
  value: string;
}

export interface SortT {
  field: string;
  direction: 'asc' | 'desc';
}

export interface QueryStateT {
  filters: FilterT[];
  sort: SortT[];
  page: number;
  pageSize: number;
}

enum Operator {
  eq = 'eq',
  ne = 'ne',
  gt = 'gt',
  lt = 'lt',
  gte = 'gte',
  lte = 'lte',
  like = 'like',
  in = 'in',
  nin = 'nin',
}

export interface CreditCardT {
  id: number;
  type_id: number;
  last_4: string;
  exp: string;
  cvv: string;
  type: CcT;
  currency: string;
  safekey: string;
  updated_at: string;
  created_at: string;
}

export interface CcT {
  id: number;
  name: string;
  working: boolean;
  headless_using: boolean;
  updated_at: string;
  created_at: string;
}

export interface ListT {
  id: number;
  name: string;
  group: string;
  sync: boolean;
  use_proxy: boolean;
  is_ds: boolean;
  accounts_count: number;
  description: string;
  updated_at: string;
  created_at: string;
}

export interface ProxyT {
  id: number;
  ip: string;
  port: number;
  user: string;
  pwd: string;
  usage: string;
  provider_id: number;
  provider: {
    id: number;
    name: string;
  };
  accounts: AccountT[];
  group_id: number;
  updated_at: string;
  created_at: string;
}

export interface ProxyGroupT {
  id: number;
  subnet: string;
  working: boolean;
  updated_at: string;
  created_at: string;
}

export interface StatusT {
  id: number;
  name: string;
  description: string;
  color: string;
  system_managed: boolean;
  updated_at: string;
  created_at: string;
}

export interface AccountsRequestsT {
  id: number;
  list_id: string;
  list: string;
  city: string;
  state: string;
  cc_type_id: number;
  cc_type: CcT;
  cc_currency: string;
  cc_info: string;
  lists: Array<ListT>;
  manager_sync: boolean;
  qty: number;
  user_email: string;
  updated_at: string;
  created_at: string;
}

export interface DomainT {
  id: number;
  domain: string;
  provider_id: number;
  provider: ProxyProviderT;
  group: ProxyGroupT;
  accounts: number;
  is_ds: boolean;
  updated_at: string;
  created_at: string;
}

export interface ProxyProviderT {
  id: number;
  name: string;
  working: boolean;
  updated_at: string;
  created_at: string;
}

export interface ProxyGroupT {
  id: number;
  subnet: string;
  working: boolean;
  updated_at: string;
  created_at: string;
}

export enum Pages {
  accounts,
  lists,
  proxies,
  domains,
}

export interface IContext {
  data: any[];
  count: number;
  fetchData: (queryStateT?: QueryStateT) => void;
  stopFetchData: () => void;
  isLoading: boolean;
}
